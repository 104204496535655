book_captcha = null
contact_captcha = null
captcha_key = '6LchoE8UAAAAAHrI0Vi_oXiYP_qsAIQpnKQGhJtd'

$(document).ready ->
  if $('#booking_form').length > 0
    $("#date-time").datetimepicker
      controlType: "select"
      dateFormat: "dd/mm/yy"
      timeFormat: "hh:mm tt"

  $("#side-menu").scotchPanel
    containerSelector: "body"
    direction: "right"
    duration: 600
    transition: "ease-in-out"
    clickSelector: ".toggle-side-menu"
    distanceX: "200px"
    enableEscapeKey: true

  $("#btnSend").click (e) ->
    e.preventDefault()

    if grecaptcha.getResponse(contact_captcha) == ""
      alert("Please complete reCAPTCHA verification.")
      return

    if $("#contact_us_form").parsley().validate()
      $.ajax
        url: "/contact/send"
        type: "POST"
        data:
          contact_name: $("#name").val().trim()
          contact_email: $("#email").val().trim()
          contact_nationality: $("#country").val().trim()
          contact_comment: $("#comment").val().trim()
          recaptcha_response_field: grecaptcha.getResponse(contact_captcha)
        error: ->
          alert("Unsuccessful. Please try again!")
        success: (data) ->
          if data.status is 1
            $('#contact_us_form')[0].reset()
            alert("Thank you!")
            grecaptcha.reset()
          else
            alert(data.error)
            grecaptcha.reset()

  $("#btnSendBooking").click (e) ->
    e.preventDefault()

    if grecaptcha.getResponse(book_captcha) == ""
      alert("Please complete reCAPTCHA verification.")
      return

    if $("#booking_form").parsley().validate()
      activities = []
      $("input[name='activities[]']:checked").each ->
        activities.push $(this).val()
      $.ajax
        url: "/booking/send"
        type: "POST"
        data:
          booking_name: $("#name").val().trim()
          booking_age: $("#age").val().trim()
          booking_first_time: (if $("#checkbox_hostel").is(":checked") then "Yes" else "No")
          booking_email: $("#email").val().trim()
          booking_phone: $("#phone").val().trim()
          booking_date_time: $("#date-time").val().trim()
          booking_persons: $("#number_persons").val().trim()
          booking_nights: $("#nights").val().trim()
          booking_room: $("#room").val().trim()
          booking_activities: activities.join()
          booking_nationality: $("#country").val().trim()
          booking_comment: $("#comment").val().trim()
          recaptcha_response_field: grecaptcha.getResponse(book_captcha)
        error: ->
          alert("Unsuccessful. Please try again!")
        success: (data) ->
          if data.status is 1
            $('#booking_form')[0].reset()
            alert("Thank you!")
            grecaptcha.reset()
          else
            alert(data.error)
            grecaptcha.reset()

  $('.btn-search').click ->
    if $('.search-text-input').val().length > 0
      location.href = '/search?q=' + $('.search-text-input').val()

  $('.search-text').keyup (e) ->
    if e.which is 13
      if $(this).val().length > 0
        location.href = '/search?q=' + $(this).val()


  if $('#locate').is(":visible")
    contact_captcha = grecaptcha.render(document.getElementById('locate_captcha_container'), {
        'sitekey': captcha_key
      })

  if $('#book-now').is(":visible")
    book_captcha = grecaptcha.render(document.getElementById('book_captcha_container'), {
        'sitekey': captcha_key
      })